<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('New real estate') }}
          </h4>
        </div>


        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="msg('HostawayId')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('HostawayId')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.hostawayId" type="number"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Branch -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Building')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Building')" rules="required">
                <v-select

                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="realEstate.buildingId"
                    :options="allBuildings"
                    label="address"
                    :reduce="(building) => building.id"
                    class="w-100"
                    :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Building')" label-for="producgdgfs">

            <b-button @click="goToNewBuilding">{{ msg('New building') }}</b-button>
            </b-form-group>
          </b-col>
          <!-- Field: First name -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Address')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Address')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.address"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('House number')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('House')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.house" type="number"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Apt')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Apt')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.apt" type="number"
                              :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Branch -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Branch')" label-for="product-branch">
              <validation-provider #default="{ errors }" :name="msg('Branch')" rules="required">
                <v-select

                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="realEstate.buid"
                    :options="allBranches"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"
                    :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Usage type')" label-for="realEstate-type">
              <validation-provider #default="{ errors }" :name="msg('Usage')" rules="required">
                <v-select
                    @option:selected="onValueUpdated"
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="realEstate.usageType"
                    :options="[
                      {value:{business:false,investment:false,living:true,waz:false},label:msg('Living')}
                      ,{value:{business:true,investment:false,living:false,waz:false},label:msg('Business')},
                      {value:{business:false,investment:true,living:false,waz:false},label:msg('Investment')}]"
                    label="label"
                    :reduce="(status) => status.value"
                    class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Real estate type')" label-for="realEstate-type"
                          :description="msg('For rent or sale?')">
              <validation-provider #default="{ errors }" :name="msg('Type')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="realEstate.marketingType"
                    class="w-100"
                    :options="[{value:{purchase:false,rent:true},label:msg('Rent')},{value:{purchase:true,rent:false},label:msg('Purchase')}]"
                    label="label"
                    :reduce="(status) => status.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Rent')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Price')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.realEstatePrices.rentTotal" type="number"
                              step="0.5" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Owner ID')" label-for="realEstate">
              <client-picker is-owner v-model="realEstate.ownerId"/>
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">

          <!-- Field: First name -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Floor')" label-for="realEstate-name">
              <b-form-input id="realEstate-name" v-model="realEstate.floorNumber" type="number"/>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="5" lg="3">
            <b-form-group :label="msg('Floor text')" label-for="realEstate-name">
              <b-form-input id="realEstate-name" v-model="realEstate.floor"/>

            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Rooms')" label-for="realEstate-name">
              <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.rooms" min="0" step="0.5"
                            type="number"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Total space')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('total space')" rules="required">
                <b-form-input id="realEstate-name" v-model="realEstate.realEstateArea.totalSpace" type="number" min="0"
                              step="0.1" :state="errors.length > 0 ? false:null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import ClientPicker from "@/components/components/picker/clientPicker.vue";

export default {
  name: 'realEstatePanel',
  components: {ClientPicker},
  data() {
    return {
      loaded: false,

      error: null,
      realEstateId: 0,
      realEstate: {
        name: '',
        type: null,
        realEstateArea: {},
        realEstatePrices: {}
      },

      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
    allBuildings: {
      type: Array,
      default: null,
      required: true
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {

    this.onLoad()
    this.$root.$on('crm::submitRealEstate', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitRealEstate', this.listeners.submit)
  },
  methods: {
    ...mapActions('realEstate', {
      _register: 'addRealEstate',
      _loadRealEstateData: 'getData'
    }),
    goToNewBuilding() {
      window.open('/buildings', '_blank')
    },
    onLoad() {
      let $this = this;
      this._loadRealEstateData().finally(() => $this.loaded = true)

    }
    ,
    createRealEstate() {
      let $this = this
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._register(this.realEstate)
            .then(
                (id) => {

                  $this.realEstateId = id
                  $this.$emit('submitted', {
                    status: true,
                    server: id,
                    id

                  })
                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
            .finally(() => {
              console.log('id: ', $this.realEstateId)
              this.$router.push('/real_estate/edit/' + $this.realEstateId)

            })
      })
    },

    validate(field) {
      return this.$v.realEstate[field] != null && this.$v.realEstate[field].$error === true
    }
    ,
    submit() {
      return this.createRealEstate()
    }

  },

}
</script>


